require('../images/bionalys.svg');

require('../template/js/modernizr.js');
var MobileDetect = require('../template/js/mobile-detect.js');
require('../template/js/progressbar/progressbar.js');
require('../template/js/bootstrap-datepicker/bootstrap-datepicker.min');
require('../template/js/bootstrap-datepicker/locales/bootstrap-datepicker.fr');
require('../template/js/mask.min');

$(document).ready(function() {
    var $body = $( 'body' ),
        $layout = $body.find( '.pageLayout' ),
        $header = $layout.find( '.pageLayout__header' ),
        $headerContainer = $header.find( '.pageLayout__header__container' ),
        $menuMobile = $headerContainer.find('.pageLayout__header__menu'),
        $menuToggle = $headerContainer.find('.pageLayout__header__mobile'),
        $menuToggleLines = $menuToggle.find('.pageLayout__header__mobile__lines'),
        $menuMobileContainer = $menuMobile.find('.pageLayout__header__menu__links'),
        $windowWidth = window.outerWidth,
        resizeTimer
    ;

    var md = new MobileDetect(window.navigator.userAgent);
    if ( $menuToggle.length ) {
        $menuToggle.on('click', function () {
            if ('none' != $menuToggle.css( 'display' ) ) {
                if ($menuToggleLines.hasClass('open')) {
                    $menuToggleLines.removeClass('open');
                    $menuMobileContainer.slideToggle(400);
                } else {
                    $menuToggleLines.addClass('open');
                    $menuMobileContainer.slideToggle(400);
                }
            } else  {
                $menuToggleLines.removeClass('open');
            }
        });
    }

    if ($('#breadcrumbstep').length) {
        var progress = $('#breadcrumbstep').data('progress');
        $('#breadcrumbstep').LineProgressbar({
                percentage: progress,
                ShowProgressCount: false,
                animation: false,
                fillBackgroundColor: '#007582',
                backgroundColor: '#fff',
            }
        );
    }

    $('a[data-scroll]').on('click', function (e) {
       e.preventDefault();
        var attr = $(this).attr('href');

        if ($(attr).length) {
            $('html, body').animate({
                scrollTop: $(attr).offset().top
            }, 400);
        }
    });

    if ($('input[data-date="true"]').length) {
        var inputDate = jQuery('input[data-date="true"]');
        $('#' + inputDate.attr('id')).datepicker({
            language: 'fr',
            autoclose: true,
            endDate : 'now',
            isDisabled: true
        });
    }

    if ($('.pageLayout__content__stepform__content').length) {
        if ($('.pageLayout__content__stepform__content').has('.field').length <  $('.pageLayout__content__stepform__content').length) {
            $('.pageLayout__content__stepform__content').each(function() {
                if ($(this).find('.field').length == 0) {
                    $(this).remove();
                }
            });
        }
    }

    if (! md.mobile()) {
        $('html').addClass('isDesktop');
        transformForm();
    }

    // INPUT SHOW/HIDE
    if (jQuery('*[data-show]').length) {
        jQuery('*[data-show]').each(function() {
            var $this = jQuery(this);
            var values = $this.data('show');
            var elmnt_show = $this;

            var required_field = false;
            if ($this.attr('required')) {
                required_field = true;
            }

            if ($this.is('select') || $this.is('input') || $this.is('textarea')) {
                elmnt_show = $this.parent().parent();
            }

            if ('object' === typeof values) {
                jQuery.each(values, function(field, value) {
                    if (jQuery('#form_' + field).length) {
                        // if select
                        if (jQuery('#form_' + field).is("select")) {
                            if (jQuery('#form_' + field).val()) {
                                if (jQuery.inArray(jQuery('#form_' + field).val(), value) >= 0) {
                                    elmnt_show.show();
                                } else {
                                    elmnt_show.hide();
                                    if (true === required_field) {
                                        $this.removeAttr('required');
                                    }
                                }
                            } else {
                                elmnt_show.hide();
                            }

                            jQuery('body').on('change', '#form_' + field, function() {
                                if ('object' == typeof value) {
                                    if (jQuery.inArray(jQuery(this).val(), value) >= 0) {
                                        elmnt_show.show();
                                        if (true === required_field) {
                                            $this.attr('required', 'required');
                                        }
                                    } else {
                                        elmnt_show.hide();

                                        // init fields in elmnt show
                                        jQuery('input[type=radio][value=2]', elmnt_show).prop('checked', true);
                                        jQuery('label#form_2_2', elmnt_show).addClass('active');
                                        jQuery('label#form_2_1', elmnt_show).removeClass('active');
                                        jQuery('option[value!=2]', elmnt_show).removeAttr('selected', 'selected');
                                        jQuery('option[value=2]', elmnt_show).attr('selected', 'selected');
                                        jQuery('select', elmnt_show).val('2');

                                        if (true === required_field) {
                                            $this.removeAttr('required');
                                        }
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    }

    $( window ).on( 'scroll' , function() {
        if (window.outerWidth >= 1200 && $('html').hasClass('isDesktop') && $('.pageLayout__content__stepform .pageLayout__content__stepform__title').length) {
            var elmnt = $('.pageLayout__content__stepform .pageLayout__content__stepform__title');
            var scrollTop = $(window).scrollTop();
            var top = elmnt.offset().top;
            var left = elmnt.offset().left;

            elmnt.css('left', left);
            if (! elmnt.data('position')) {
                elmnt.attr('data-position', top);
            } else {
                top = elmnt.data('position');
            }

            if (scrollTop >= top) {
                if (! elmnt.hasClass("pageLayout__content__stepform__title-fixed")) {
                    elmnt.addClass("pageLayout__content__stepform__title-fixed");
                }
            } else {
                elmnt.removeClass("pageLayout__content__stepform__title-fixed");
            }
        }
    } );

    // INPUT MASK
    if (jQuery('input[data-validate]').length) {
        // phone
        if (jQuery('input[data-validate="phone"]').length) {
            jQuery('input[data-validate="phone"]').mask('00 00 00 00 00');
        }

        // postalCode
        if (jQuery('input[data-validate="postalCode"]').length) {
            jQuery('input[data-validate="postalCode"]').mask('00000');
        }
    }

    $('body').on('click', 'button[data-loadclick]', function() {
       $(this).addClass('btn-load');
    });

    $('body').on('click', '.content-field-radio input[type=radio]', function(e) {
        let value = $(this).val();
        let label = $(this).parent();
        let container = label.parent();
        let select = '';
        let hasRequired = false;
        if (container.data('select') && $('select#' + container.data('select')).length) {
            select = $('select#' + container.data('select'));

            if (select.parent().parent().find('label:eq(0)').hasClass('required')) {
                hasRequired = true;
            }
        }

        if (select) {
            if (label.hasClass('active') && false === hasRequired) {
                label.toggleClass('active');
                select.val('');
                $('option', select).removeAttr('selected', 'selected');
                $(this).attr('checked', false);
            } else if (! label.hasClass('active')) {
                if ($('option[value=' + value + ']', select).length) {
                    select.val(value);
                    $('option[value!=' + value + ']', select).removeAttr('selected', 'selected');
                    $('option[value=' + value + ']', select).attr('selected', 'selected');
                    select.change();
                    label.toggleClass('active');
                    $('label[id!='+label.attr('id')+']', container).removeClass('active');
                }
            }
        }
    });

    $('body').on('click', '.content-field-checkbox input[type=checkbox]', function(e) {
        if ($(this).is(':checked')) {
            $('label[for="'+$(this).attr('id')+'"]').addClass('active');
        } else {
            $('label[for="'+$(this).attr('id')+'"]').removeClass('active');
        }
    });

    $( window )
        .on( 'load', onLoad )
        .on( 'resize', function() {
            clearTimeout( resizeTimer );
            resizeTimer = setTimeout( function() {
                $windowWidth = window.innerWidth;
                onResize();
            }, 300 );
        } )
    ;

    function onLoad() {
        $windowWidth = window.innerWidth;

        adjustMenuTop();
    }

    function onResize() {
        adjustMenuTop();
    }

    function adjustMenuTop() {
        if ('none' != $menuToggle.css('display')) {
            var top = $header.outerHeight();

            var mobileHeight ='calc( 100vh - '+ top +'px)';
            $menuMobileContainer.css('max-height', mobileHeight);
            $menuMobileContainer.css('height', mobileHeight);
        }

        if ( $menuToggleLines.hasClass('open') && $windowWidth >= 1024 ) {
            $menuToggleLines.removeClass('open');
            $menuMobileContainer.slideToggle(400);
        }
    }
});

function transformForm()
{
    if ($('form').find('select')) {
        $('form select').each(function () {
            let select = $(this);
            let id = select.attr('id');
            let name = select.attr('name');
            let options = $('option', select);

            if (options.length > 0) {
                let contentFields = jQuery('<div/>', {
                    "class": 'content-field-radio content-field-radio-' + id,
                    "data-select": id,
                }).prependTo(select.parent());

                options.each(function() {
                    if ($(this).val()) {
                        let selected = false;

                        if ($(this).attr("selected")) {
                            selected = true;
                        }

                        let input = $('<input />', {
                            type : "radio",
                            name : name,
                            checked: selected,
                            value : $(this).val()
                        });

                        $("<label />", {
                            id: id + '_' + $(this).val(),
                            class: (selected ? 'active' : ''),
                            appendTo: contentFields,
                            append: [input, $(this).text()] // include our $input and also some text description
                        });
                    }
                });
                // select.before();


            }
        });
    }
}